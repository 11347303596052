// src/pages/TablePage.jsx

import React, { useEffect, useState, useMemo } from "react";
import fetcher_data from "../data_fetcher";
import courier_fetch from "../courier_fetcher";
import broker_fetch from "../broker_fetcher";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FiMenu,
  FiChevronLeft,
  FiChevronRight,
  FiX,
  FiDownload,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi"; // Removed FiSort and FiSortUp/FiSortDown
import debounce from "lodash.debounce";

const TablePage = ({ app, setApp }) => {
  const [selectedData, setSelectedData] = useState(
    app === "banking"
      ? fetcher_data.reads[0]
      : app === "courier"
        ? courier_fetch.reads[0]
        : broker_fetch.reads[0]
  );
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [reload, setReload] = useState(1);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default to closed on mobile
  const [isHovering, setIsHovering] = useState(false);

  const writes =
    app === "banking"
      ? fetcher_data.reads
      : app === "courier"
        ? courier_fetch.reads
        : app === "broker"
          ? broker_fetch.reads
          : [];
  const dropdownItems = writes.map((item) => item.name);

  const headers = data.length > 0 ? [...new Set(data.flatMap(Object.keys))] : [];

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Adjust as needed

  // Function to fetch data based on selectedData and app
  async function fetchData() {
    toast.dismiss(); // Dismiss any existing toasts
    toast.info("Fetching data...", {
      theme: "dark",
      position: "bottom-center",
      autoClose: false,
      toastId: "fetching",
    });

    let read_data_to_fetch = null;

    if (app === "banking") {
      read_data_to_fetch = fetcher_data.reads.find(
        (item) => item.name === selectedData.name
      );
    }

    if (app === "courier") {
      read_data_to_fetch = courier_fetch.reads.find(
        (item) => item.name === selectedData.name
      );
    }
    if (app === "broker") {
      read_data_to_fetch = broker_fetch.reads.find(
        (item) => item?.name === selectedData?.name
      );
    }

    let fetchedData = null;

    try {
      if (app === "banking") {
        fetchedData = await fetcher_data?.getData(
          read_data_to_fetch.path,
          read_data_to_fetch.formatter_function
        );
      }

      if (app === "courier") {
        fetchedData = await courier_fetch?.getData(
          read_data_to_fetch?.path,
          read_data_to_fetch.formatter_function
        );
      }

      if (app === "broker") {
        fetchedData = await broker_fetch?.getData(
          read_data_to_fetch?.path,
          read_data_to_fetch.formatter_function
        );
      }

      if (fetchedData === "err") {
        toast.error("Error fetching data");
        setTimeout(() => {
          toast.dismiss("fetching");
        }, 2000);
        return;
      }
      if (fetchedData === "Unauthorized") {
        toast.error("Expired Authentication Token. Please login again.");
        setTimeout(() => {
          toast.dismiss("fetching");
          window.location.href = "/login";
        }, 2000);
        return;
      }
      setData(fetchedData);
      toast.dismiss("fetching");
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred.");
      setTimeout(() => {
        toast.dismiss("fetching");
      }, 2000);
    }
  }

  useEffect(() => {
    fetchData();
    // Reset pagination when data changes
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, selectedData]);

  // Debounced filter handler
  const handleFilterChange = useMemo(
    () =>
      debounce((value) => {
        setFilter(value);
        setCurrentPage(1); // Reset to first page on filter
      }, 300),
    []
  );

  const onFilterChange = (e) => {
    handleFilterChange(e.target.value);
  };

  // Filter data based on the filter input
  const filteredData = useMemo(() => {
    if (!filter) return data;
    return data.filter((row) =>
      headers.some((header) =>
        row[header]?.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [data, filter, headers]);

  // Sort data based on sortConfig
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return filteredData;
    const sorted = [...filteredData].sort((a, b) => {
      const aVal = a[sortConfig.key];
      const bVal = b[sortConfig.key];
      if (aVal < bVal) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [filteredData, sortConfig]);

  // Pagination logic
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage]);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Handle sorting when a header is clicked
  const handleSort = (header) => {
    let direction = "ascending";
    if (sortConfig.key === header && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: header, direction });
  };

  // Handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle item selection in the sidebar
  const handleItemClick = (item) => {
    setData([]);
    setSelectedData(item);
    setReload(reload + 1);
    setIsSidebarOpen(false); // Auto-collapse sidebar on selection
  };

  // Export data as CSV
  const exportToCSV = () => {
    if (sortedData.length === 0) {
      toast.warning("No data to export.");
      return;
    }
    const csvContent = [
      headers.join(","),
      ...sortedData.map((row) =>
        headers.map((header) => `"${row[header] || ""}"`).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${selectedData.name}_data.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Data exported successfully!");
  };

  return (
    <div className="w-full h-screen flex bg-gray-800 text-white">
      {/* Desktop Sidebar */}
      <div
        className={`${isSidebarOpen || isHovering ? "w-64" : "w-16"
          } bg-gray-900 text-white flex flex-col transition-all duration-300
        hidden md:flex
        relative`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        aria-label="Sidebar"
      >
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4">
          {(isSidebarOpen || isHovering) && (
            <h2 className="text-lg font-semibold">Actions</h2>
          )}
          <button
            onClick={toggleSidebar}
            className="focus:outline-none"
            aria-label={isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
          >
            {isSidebarOpen ? (
              <FiChevronLeft size={24} />
            ) : (
              <FiChevronRight size={24} />
            )}
          </button>
        </div>

        {/* Sidebar Navigation */}
        <nav className="flex-1 overflow-y-auto">
          <ul className="p-2">
            {writes.map((item, index) => (
              <li key={index} className="mb-1">
                <button
                  onClick={() => handleItemClick(item)}
                  className={`w-full text-left px-4 py-2 rounded hover:bg-gray-700 ${selectedData.name === item.name
                    ? "bg-gray-700 font-semibold"
                    : ""
                    } flex items-center`}
                  aria-current={
                    selectedData.name === item.name ? "page" : undefined
                  }
                >
                  {/* Optional: Add icons based on item.type or other properties */}
                  {(isSidebarOpen || isHovering) ? (
                    <span>{item.name}</span>
                  ) : (
                    <span className="mx-auto" title={item.name}>
                      {item.name.charAt(0)}
                    </span>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 md:hidden">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={toggleSidebar}
            aria-hidden="true"
          ></div>
          {/* Sidebar */}
          <div
            className="fixed inset-y-0 left-0 w-64 bg-gray-900 text-white flex flex-col"
            aria-label="Mobile Sidebar"
          >
            {/* Sidebar Header */}
            <div className="flex items-center justify-between p-4">
              <h2 className="text-lg font-semibold">Actions</h2>
              <button
                onClick={toggleSidebar}
                className="focus:outline-none"
                aria-label="Close sidebar"
              >
                <FiX size={24} />
              </button>
            </div>

            {/* Sidebar Navigation */}
            <nav className="flex-1 overflow-y-auto">
              <ul className="p-2">
                {writes.map((item, index) => (
                  <li key={index} className="mb-1">
                    <button
                      onClick={() => handleItemClick(item)}
                      className={`w-full text-left px-4 py-2 rounded hover:bg-gray-700 ${selectedData.name === item.name
                        ? "bg-gray-700 font-semibold"
                        : ""
                        } flex items-center`}
                      aria-current={
                        selectedData.name === item.name ? "page" : undefined
                      }
                    >
                      <span>{item.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-1 p-8 overflow-hidden flex flex-col">
        {/* Mobile Toggle Button */}
        <div className="md:hidden mb-4">
          <button
            onClick={toggleSidebar}
            className="focus:outline-none"
            aria-label="Open sidebar"
          >
            <FiMenu size={24} />
          </button>
        </div>

        {/* Header Section */}
        <div className="flex items-end justify-between gap-4 flex-wrap mb-6">
          <div className="grid gap-2">
            <h1 className="text-4xl font-bold text-white">Reading {selectedData.name}</h1>
            <p className="text-gray-400">
              Fetch and filter data from the backend.
            </p>
          </div>
          {/* Export Button */}
          <button
            onClick={exportToCSV}
            className="flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg focus:outline-none transition-colors"
            aria-label="Export data as CSV"
          >
            <FiDownload className="mr-2" />
            Export CSV
          </button>
        </div>

        {/* Filter Input */}
        <div className="flex items-center justify-between gap-4 mb-4">
          <input
            type="text"
            placeholder="Filter data"
            onChange={onFilterChange}
            className="w-full p-3 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all"
            aria-label="Filter data"
          />
        </div>

        {/* Data Table and Pagination Container */}
        <div className="relative flex-1 flex flex-col overflow-auto bg-opacity-60 bg-gray-800 backdrop-blur-md rounded-lg shadow-lg mt-6 border border-gray-700">
          {/* Table Container with Horizontal Scroll */}
          <div className="overflow-x-auto flex-1 pb-16">
            <table className="table-auto w-full min-w-max bg-opacity-50">
              <thead className="sticky top-0 bg-gradient-to-r from-indigo-600 to-purple-600 text-white z-10 shadow-md">
                <tr>
                  {headers.map((header, i) => (
                    <th
                      key={i}
                      className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wide border-b border-gray-700 cursor-pointer select-none"
                      onClick={() => handleSort(header)}
                      scope="col"
                      aria-sort={
                        sortConfig.key === header
                          ? sortConfig.direction === "ascending"
                            ? "ascending"
                            : "descending"
                          : "none"
                      }
                    >
                      <div className="flex items-center">
                        {header.charAt(0).toUpperCase() + header.slice(1)}
                        <span className="ml-1">
                          {sortConfig.key === header ? (
                            sortConfig.direction === "ascending" ? (
                              <FiArrowUp />
                            ) : (
                              <FiArrowDown />
                            )
                          ) : null}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {sortedData.length === 0 ? (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="px-4 py-3 text-center text-gray-500"
                    >
                      {data.length === 0 ? (
                        <div className="flex items-center justify-center p-4">
                          {/* <svg
                            className="animate-spin h-5 w-5 text-gray-400 mr-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8v8H4z"
                            ></path>
                          </svg> */}
                          No Data
                        </div>
                      ) : (
                        "No data found."
                      )}
                    </td>
                  </tr>
                ) : (
                  paginatedData.map((item, i) => (
                    <tr key={i} className="hover:bg-gray-700 transition-all">
                      {headers.map((header, j) => (
                        <td
                          key={j}
                          className="px-4 py-3 text-sm text-gray-200 border-b border-gray-700 whitespace-nowrap"
                        >
                          {item[header] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Sticky Pagination Controls */}
          {sortedData.length > itemsPerPage && (
            <div
              className="sticky bottom-0 flex justify-between items-center p-4 bg-gray-900 bg-opacity-75 z-20"
              style={{ backdropFilter: "blur(10px)" }} // Optional: Adds a blur effect for better visibility
            >
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.max(prev - 1, 1))
                }
                disabled={currentPage === 1}
                className={`flex items-center px-3 py-1 rounded ${currentPage === 1
                  ? "bg-gray-600 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
                  } focus:outline-none`}
                aria-label="Previous page"
              >
                <FiChevronLeft />
                <span className="ml-2">Previous</span>
              </button>
              <span className="text-gray-400">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className={`flex items-center px-3 py-1 rounded ${currentPage === totalPages
                  ? "bg-gray-600 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
                  } focus:outline-none`}
                aria-label="Next page"
              >
                <span className="mr-2">Next</span>
                <FiChevronRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TablePage;
